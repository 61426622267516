import request from '@/utils/request';

const PATH = '/product';

export const getProductList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
    page: listQuery.page - 1,
  },
});

export const getProductDetail = (productIdx: number) => request({
  url: `${PATH}/${productIdx}`,
  method: 'get',
});
